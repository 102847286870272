import useMousePosition from "../../functions/useMousePosition";


interface Props{
    isHovering: boolean,
    hoveringSlider: boolean,
    activeBackground: string
}

function AICursor({isHovering, hoveringSlider, activeBackground}:Props){

    const {x, y} =  useMousePosition()

    return(
        <div className={`ai-cursor background-${activeBackground} ${isHovering ? 'ai-cursor-hover': ''} ${hoveringSlider ? 'ai-slider-cursor': ''}`} style={{ left: `${x}px`, top: `${y}px` }}>
            {
                hoveringSlider &&
                <span>DRAG</span>
            }

        </div>
    )
}

export default AICursor