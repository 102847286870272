import {useEffect, useState} from "react";

function useScrollPosition(){

    function getScrollPosition(){
        setScrollPosition(window.scrollY)
    }

    /*
    * useState
    * */
    const [scrollPosition, setScrollPosition] = useState<any>()

    /*
    * useEffect
    * */
    useEffect(()=>{
        window.addEventListener('scroll', getScrollPosition, {passive: true});
        return () => {
            document.removeEventListener("scroll", getScrollPosition);
        };
    }, [])

    return  scrollPosition;
}

export default useScrollPosition;