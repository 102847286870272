import React, {lazy, Suspense, useEffect, useState} from "react";
import {Routes, Route, useLocation} from "react-router-dom";

import AIHeader from "./components/AIHeader/AIHeader";
import AICursor from "./components/AICursor/AICursor";
import useScrollPosition from "./functions/useScrollPosition";

//Pages
const HomePage = lazy(() => import ("./pages/HomePage"));
const ServicesPage = lazy(() => import ("./pages/ServicesPage"));
const CareersPage = lazy(() => import("./pages/CareersPage"))
const CareersSinglePage = lazy(() => import("./pages/CareersSinglePage"))
const AnnouncementsPage = lazy(() => import("./pages/AnnouncementsPage"))
const BlogPage = lazy(() => import ("./pages/BlogPage"));
const BlogSinglePage = lazy(() => import ("./pages/BlogSinglePage"));
const NotFoundPage = lazy(() => import("./pages/NotFoundPage"));
// import AIFooter from "./components/AIFooter/AIFooter";
const AIFooter = lazy(()=> import('./components/AIFooter/AIFooter'))
//Interface
export interface ContextTypes {
    activeBackground: string,
    setActiveBackground: React.Dispatch<React.SetStateAction<string>>
    isHovering: boolean,
    setIsHovering: React.Dispatch<React.SetStateAction<boolean>>,
    hoveringSlider : boolean,
    setHoveringSlider: React.Dispatch<React.SetStateAction<boolean>>,
    navOpen: boolean,
    handleToggleNav: Function,
    contactOpen: boolean,
    handleToggleContact: Function,
    scrollPosition: number,
    screenSize: string,
    setScreenSize: Function
}

//Context
export const AIContext = React.createContext<ContextTypes>({
    activeBackground: 'white',
    setActiveBackground: ()=>{},
    isHovering: false,
    setIsHovering: ()=>{},
    hoveringSlider: false,
    setHoveringSlider: () => {},
    navOpen: false,
    handleToggleNav: () => {},
    contactOpen: false,
    handleToggleContact: () => {},
    scrollPosition: 0,
    screenSize:'destkop',
    setScreenSize: ()=>{}
});


function AIRouteIndex() {

    /*
    * useLocation
    * */
    const {pathname, hash} = useLocation()

    /*
    * useState
    * */
    const [activeBackground, setActiveBackground] = useState<string>('')
    const [isHovering, setIsHovering] = useState<boolean>(false)
    const [hoveringSlider, setHoveringSlider] = useState<boolean>(false)
    const [navOpen, setNavOpen] = useState<boolean>(false);
    const [contactOpen, setContactOpen] = useState<boolean>(false)
    const [scrollPosition, setScrollPosition] = useState<number>(0)
    const [screenSize, setScreenSize] = useState<string>('desktop')

    /*
    *  scrollPosition
    * */
    const xPos = useScrollPosition();

    useEffect(()=>{
        setScrollPosition(xPos)
    }, [xPos])


    /*
    *  Toggle Navigation
    * */
    const handleToggleNav = () => {
        if(navOpen){
            setNavOpen(!navOpen)
        }
        if(!navOpen && !contactOpen){
            setNavOpen(!navOpen)
        }
        if(contactOpen){
            setContactOpen(!contactOpen)
        }
    }

    /*
    * Toggle Contact
    * */
    const handleToggleContact = () => {
        setContactOpen(!contactOpen)
        setNavOpen(false)
    }


    //Context Values
    const value = {
        activeBackground,
        setActiveBackground,
        isHovering,
        setIsHovering,
        hoveringSlider,
        setHoveringSlider,
        navOpen,
        handleToggleNav,
        contactOpen,
        handleToggleContact,
        scrollPosition,
        screenSize,
        setScreenSize
    };


    /*
    * useEffect
    * */
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "auto"
        })
    }, [pathname])

    useEffect(()=>{
        if(hash.length > 0 && document.querySelector(hash) !== null){
            document.querySelector(hash)!.scrollIntoView({behavior: 'smooth'})
        }
    }, [hash, hash.length > 0 && document.querySelector(hash)])


    useEffect(()=>{
        updateDimensions();
        window.addEventListener('resize', updateDimensions)
        return ()=>
            window.removeEventListener('resize', updateDimensions)
    }, [])


    //Update Dimensions
    const updateDimensions = () => {

        let width:number = window.innerWidth;
        // setScreenSize(width)
        if(width > 1920){
            setScreenSize('desktop-max')
        }
        else if(width <= 1920 && width > 1600){
            setScreenSize('desktop-fhd')
        } else if(width < 1600 &&  width > 1400){
            setScreenSize('desktop-wide')
        } else if(width < 1400 && width > 900){
            setScreenSize('desktop')
        } else if(width < 900 && width > 600){
            setScreenSize('tablet')
        } else if(width < 600){
            setScreenSize('mobile')
        }

}



    return (
        <>
            <AIContext.Provider value={value}>
                <AIHeader/>
                    <main className={`ai-page-wrapper ${activeBackground}`}>
                        <Suspense fallback={<div></div>}>
                            <Routes>
                                <Route index element={<HomePage/>}/>
                                <Route path={'/services'} element={<ServicesPage/>}/>
                                <Route path={'/careers'} element={<CareersPage/>}/>
                                <Route path={'/careers/:slug'} element={<CareersSinglePage/>}/>
                                <Route path={'/announcements'} element={<AnnouncementsPage/>}/>
                                {/*<Route path={'/blog'} element={<BlogPage/>}/>*/}
                                {/*<Route path={'/blog/paged/:page'} element={<BlogPage/>}/>*/}
                                {/*<Route path={'/blog/:slug'} element={<BlogSinglePage/>}/>*/}
                                <Route path={'*'} element={<NotFoundPage/>}/>
                            </Routes>
                        </Suspense>
                    </main>
                <AIFooter/>

                <AICursor isHovering={isHovering} hoveringSlider={hoveringSlider} activeBackground={activeBackground}/>
            </AIContext.Provider>
        </>
    )
}

export default AIRouteIndex;