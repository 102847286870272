import React, {useContext} from "react";
import {Link} from 'react-router-dom';
import {ReactComponent as Logo} from "../../assets/images/General/kinga-logo.svg";
import {Col, Container, Row} from "react-bootstrap";
import AINavigation from "../AINavigation/AINavigation";

//Assets
import {ReactComponent as ContactSidebarIcon} from "../../assets/images/AIHeader/kinga-contact-us-sidebar-icon.svg";
import { ReactComponent as MobileContactToggle } from "../../assets/images/AIHeader/kinga-mobile-contact-toggle.svg";
import AIContact from "../AIContact/AIContact";
import {AIContext} from "../../Router";
import TextEffect from "../../functions/TextEffect";
import {ReactComponent as HeaderX} from "../../assets/images/Homepage/kinga-text-animation-x.svg";

function AIHeader(){

    /*
      * Cursor Effect
      * */

    //context
    const value = useContext(AIContext)

    //Cursor Size
    function handleMouseOver (){
        value.setIsHovering(true)
    }

    function handleMouseLeave(){
        value.setIsHovering(false)
    }

    return(
        <>
            <header className={`ai-site-header ${value.navOpen ? 'ai-navigation-open' : 'ai-navigation-closed'} ${value.contactOpen ? 'ai-contact-open' : 'ai-contact-closed'} ` + value.activeBackground }>
                <Container>
                    <Row className={'justify-content-between g-0 ai-p-x-y-0'}>
                        <Col lg={2} className={'ai-site-logo'}>
                            <div className={"ai-image-wrapper"}>
                                <Link to={'/'} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
                                    <Logo/>
                                </Link>
                            </div>
                        </Col>
                        <Col lg={1}>
                            <div className={`ai-navigation-toggle`} onClick={()=> value.handleToggleNav()} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
                                <div className={'line'}></div>
                                <div className={'line'}></div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </header>
            <AINavigation className={value.navOpen ? 'ai-navigation-open' : 'ai-navigation-closed'} toggleNavigation={value.handleToggleNav} toggleContact={value.handleToggleContact}/>

            <div className={`ai-fixed-socials ${value.scrollPosition > 20 && 'visible'} ` + value.activeBackground }  onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
                <a href={'https://www.linkedin.com/company/kinga-global'} rel="noreferrer" target={'_blank'}>LinkedIn</a>
                <span className={'ai-spacing-dot'}>&#8226;</span>
                <a href={'https://www.instagram.com'} rel="noreferrer" target={'_blank'}>Instagram</a>
            </div>
            <div className={`ai-fixed-contact ${value.scrollPosition > 20 && 'visible'}`} onClick={()=> value.handleToggleContact()} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} >
                {
                    value.screenSize === 'mobile' ?
                        <span className={'ai-mobile-contact-toggle'}>
                            <MobileContactToggle/>
                        </span>
                        :
                        <>
                            <div className={'ai-contact-sidebar__icon'}>
                                <ContactSidebarIcon/>
                            </div>
                            <div className={'ai-contact-sidebar__links'}>
                            <span>Contact Us</span>
                            <span className={'ai-spacing-dot'}>&#8226;</span>
                            <span>Contact Us</span>

                            </div>
                        </>
                }
            </div>

            <AIContact/>
            <Container fluid={true} className={'g-0'}>
                <Row className={`g-0 ai-homepage-header__bottom-text ai-pt-2 ai-pb-0 ${value.activeBackground}`}>
                    <Col lg={6} md={6}>
                                {TextEffect('/ /  i k i n g a • i k i n g', 0)}
                    </Col>
                    <Col lg={6} md={6}>
                        {TextEffect('/ /  i k i n g a X i k i n g' , 5000)}
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default AIHeader;