// import {MouseEvent, useEffect, useRef} from "react";
//
//
// function TextEffect(text:string){
//
//     let originalText = text;
//     let originalIndex:number = 0;
//     let glitchIndex:number = 0;
//<!--                            ${originalText[originalIndex]}-->
//
//    //  //useRef
//    //  const GlitchText = useRef<HTMLElement> (null)
//    //
//    //  function renderGlitch(){
//    //
//    //
//    //      setTimeout(()=>{
//    //
//    //          if(GlitchText.current !== null && GlitchText.current !== undefined){
//    //
//    //              if(GlitchText.current.children[glitchIndex] !== null && GlitchText.current.children[glitchIndex] !== undefined && glitchIndex <= GlitchText.current.children.length ){
//    //                  GlitchText.current.children[glitchIndex].children[0].innerHTML = `<span className="ai-letter">${glitches[Math.floor(Math.random() * glitches.length)]}</span>`
//    //                  glitchIndex++
//    //                  renderGlitch();
//    //
//    //              }
//    //              // else if(GlitchText.current.children[originalIndex] !== undefined ) {
//    //              //     restoreText()
//    //              // } else{
//    //              //     return false
//    //              // }
//    //          }
//    //      }, 50)
//    //  }
//    //
//    //  function restoreText(){
//    //      setTimeout(()=>{
//    //          if(GlitchText.current !== null && GlitchText.current !== undefined){
//    //            if(originalIndex <= GlitchText.current.children.length && GlitchText.current.children[originalIndex] !== null && GlitchText.current.children[originalIndex] !== undefined){
//    //                GlitchText.current.children[originalIndex].innerHTML = `<span className="ai-letter">${originalText[originalIndex]}</span>`
//    //                originalIndex++
//    //                if(originalIndex < GlitchText.current.children.length){
//    //                    restoreText()
//    //                }
//    //            }
//    //          }
//    //      }, 50)
//    //  }
//    //
//    //  //Set Time Interval
//    //
//    //  useEffect(()=>{
//    //      renderGlitch()
//    //      setTimeout(()=>{
//    //          glitchIndex = 0;
//    //          originalIndex = 0
//    //          restoreText()
//    //      }, 1500)
//    //      setInterval(()=>{
//    //          renderGlitch()
//    //          setTimeout(()=>{
//    //              glitchIndex = 0;
//    //              originalIndex = 0
//    //              restoreText()
//    //          }, 1500)
//    //      }, 15000)
//    //  },[])
//    //
//    //
//    //
//    //
//    //
//    //
//    //  //Hover Function
//    //  function handleHover(e:MouseEvent){
//    //          renderGlitch()
//    //
//    //  }
//    //
//    // function handleMouseLeaves(e:MouseEvent){
//    //     if(GlitchText.current !== null && GlitchText.current !== undefined){
//    //         setTimeout(()=>{
//    //             glitchIndex = 0;
//    //             originalIndex = 0
//    //             restoreText()
//    //         }, 50 * GlitchText.current.children.length)
//    //     }
//    //  }
//
//
//
//     //Glitch elements
//     const glitches = '`¡™£¢∞§¶•ªº–≠åß∂ƒ©˙∆˚¬…æ≈ç√∫˜µ≤≥÷/?<>/'.split('')
//
//     //Split Text to array
//     const textArray = text.split('')
//
//     return (
//         <span className={'ai-glitch-text'} ref={GlitchText}>
//            {
//                textArray.map((letter:string, index:number)=>(
//                    <span className={'ai-glitch-content'} key={index}>
//                        <span className={'ai-letter'}>{letter}</span>
//                    </span>
//                ))
//            }
//         </span>
//     )
// }
//
// export default TextEffect



import React, { useRef, useEffect } from "react";
import { render } from "react-dom";
import {ReactComponent as HeaderX} from "../assets/images/Homepage/kinga-text-animation-x.svg";

export default function TextEffect(text:string, initialDelay:number) {
    /*
      useRef
    */
    const GlitchText = useRef<any>(null);

    let originalText = text;
    let originalIndex = 0;
    let glitchIndex = 0;
    let outIndex = text.length - 1;

    /*
      useEffect
    */
    useEffect(() => {
        setTimeout(() => {
            renderGlitchNew();
        }, initialDelay);
        // Repeat Effect
        setInterval(() => {
            originalIndex = 0;
            glitchIndex = 0;
            outIndex = text.length - 1;

            renderGlitchNew();
        }, 15000 + initialDelay);
    }, []);

    function renderGlitchNew() {
        setTimeout(() => {
            if (GlitchText.current !== null && GlitchText.current !== undefined) {
                if (
                    GlitchText.current.children[glitchIndex] !== null &&
                    GlitchText.current.children[glitchIndex] !== undefined
                ) {
                    GlitchText.current.children[glitchIndex].classList =
                        "ai-glitch-content";
                    GlitchText.current.children[glitchIndex].children[0].innerHTML = `
            <span className="ai-letter">
              ${glitches[Math.floor(Math.random() * glitches.length)]}
            </span>`;
                    glitchIndex++;
                    if (glitchIndex !== GlitchText.current.children.length) {
                        renderGlitchNew();
                    } else if (originalIndex <= GlitchText.current.children.length) {
                        restoreText();
                    }
                }
            }
        }, 50);
    }

    function restoreText() {
        setTimeout(() => {
            if (GlitchText.current !== null && GlitchText.current !== undefined) {
                if (
                    originalIndex <= GlitchText.current.children.length &&
                    GlitchText.current.children[originalIndex] !== null &&
                    GlitchText.current.children[originalIndex] !== undefined
                ) {
                    GlitchText.current.children[originalIndex].innerHTML = `
                        <span class="ai-letter">
                                ${
                        originalText[originalIndex] === 'X' ? <h1>Test</h1> : originalText[originalIndex] 
                                }
                        </span>
                        `;
                    originalText[originalIndex] === 'X' ? GlitchText.current.children[originalIndex].innerHTML = `
                        <span class="ai-letter">
                           <svg style="display: inline" width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_20_424)">
                                    <path d="M50.614 13.3278L38.1744 0.888184L25.7881 13.2744L13.4019 0.888184L0.96228 13.3278L13.3485 25.714L0.96228 38.0896L13.4019 50.5399L25.7881 38.1536L38.1744 50.5399L50.614 38.0896L38.2277 25.714L50.614 13.3278Z" fill="black"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_20_424">
                                    <rect width="49.6517" height="49.6517" fill="white" transform="translate(0.96228 0.888184)"/>
                                    </clipPath>
                                </defs>
                            </svg>

                        </span>
                    ` : GlitchText.current.children[originalIndex].innerHTML = `
                        <span class="ai-letter">
                          ${originalText[originalIndex]}
                        </span>
                    `
                    originalIndex++;
                    if (originalIndex < GlitchText.current.children.length) {
                        restoreText();
                    } else if (outIndex > 0) {
                        setTimeout(() => {
                            transitionOut();
                        }, 1000);
                    }
                }
            }
        }, 50);
    }

    function transitionOut() {
        setTimeout(() => {
            if (GlitchText.current !== null && GlitchText.current !== undefined) {
                if (
                    GlitchText.current.children[outIndex] !== null &&
                    GlitchText.current.children[outIndex] !== undefined
                ) {
                    GlitchText.current.children[0].className += " animate-out-text";
                    GlitchText.current.children[outIndex].className +=
                        " animate-out-text";
                    setTimeout(() => {
                        GlitchText.current.children[outIndex].children[0].innerHTML = `
            <span class="ai-letter">
              ${glitches[Math.floor(Math.random() * glitches.length)]}
            </span>`;
                    }, 100);

                    outIndex = outIndex - 1;
                    if (outIndex > 0) {
                        transitionOut();
                    }
                }
            }
        }, 50);
    }

    /*
      Glitches
    */
    const glitches = "`¡™£¢∞§¶•ªº–≠åß∂ƒ©˙∆˚¬…æ≈ç√∫˜µ≤≥÷/?<>/";

    /*
      Split Text to array
    */
    const textArray = Array.apply(null, Array(originalText.length));

    return (
        <span ref={GlitchText}>
      {textArray.map((letter:any, index) => (
          <span className={"ai-glitch-content"} key={index}>
          <span className={"ai-letter"}>{letter}</span>
        </span>
      ))}
    </span>
    );
}
