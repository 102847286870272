import {Col, Container, Row} from "react-bootstrap";
import {useContext} from "react";
import {AIContext} from "../../Router";

function AIContact(){

    function handleMouseOver (){
        value.setIsHovering(true)
    }

    function handleMouseLeave(){
        value.setIsHovering(false)
    }

    /*
    Context
    =============*/
    const value = useContext(AIContext)


    return(
        <section className={`ai-contact-us ${value.contactOpen ? 'open' : 'closed'}`}>
            <Container className={'g-0'}>
                <Row className={'ai-pt-0 ai-pb-5 g-0'}>
                    <Col lg={12} className={'ai-contact-us__title'}>
                        <h1>Contact</h1>
                    </Col>
                </Row>
                <Row className={'g-0 ai-pt-0 ai-pb-5 ai-contact-us__content'}>
                    <Col lg={{offset: 5, span: 6}} className={'ai-contact-us__content-intro'}>
                        <div className="ai-contact-us__animation-wrapper" style={{'--transition-delay': 1} as React.CSSProperties}>
                            <p>Embrace change and discover infinite possibilities. Thanks for your interest in KingaGlobal. We’d love to chat more.</p>
                        </div>
                    </Col>
                    <Col lg={{offset: 5, span: 6}} className={'ai-contact-us__email'}>
                        <div className="ai-contact-us__animation-wrapper" style={{'--transition-delay': 2} as React.CSSProperties}>
                            <span className={'ai-text-small ai-font-medium'}>General inquiries.</span>
                        </div>
                        <div className="ai-contact-us__animation-wrapper" style={{'--transition-delay': 3} as React.CSSProperties}>
                            <a onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} href={'mailto:contact@kingaglobal.com'}>contact@kingaglobal.com</a>
                        </div>
                    </Col>
                </Row>
                <Row className={'ai-pt-0 ai-pb-5 g-0'}>
                    <Col lg={{offset: 5, span: 6}} className={'ai-contact-us__ready'}>
                        <div className="ai-contact-us__animation-wrapper" style={{'--transition-delay': 4} as React.CSSProperties}>
                            <span>Ready to collaborate?</span>
                        </div>
                        <div className="ai-contact-us__animation-wrapper" style={{'--transition-delay': 5} as React.CSSProperties}>
                            <p>Set up a no-strings-attached 30-minute meeting with
                                Us. Talk to us about your unique requirements and we’ll tell you how we can help.
                                No obligations, no cost.</p>
                        </div>

                    </Col>
                </Row>
                <Row className={'ai-pt-0 ai-pb-5 g-0'}>
                    <Col lg={{offset: 5, span: 6}} className={'ai-contact-us__email'}>
                        <div className="ai-contact-us__animation-wrapper" style={{'--transition-delay': 6} as React.CSSProperties}>
                            <span className={'ai-text-small ai-font-medium'}>Contact</span>
                        </div>
                        <div className="ai-contact-us__animation-wrapper" style={{'--transition-delay': 7} as React.CSSProperties}>
                            <a onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}  href={'mailto:innocent@kingaglobal.com'}>innocent@kingaglobal.com</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default AIContact;