import {Col, Container, Row} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import React, {useContext, useEffect} from "react";
import {AIContext} from "../../Router";


//Prop types
//Type Declarations
interface Props {
    toggleNavigation: Function
    toggleContact: Function
    className?: string
}

function AINavigation({toggleNavigation, toggleContact, className}:Props){
    const location = useLocation();
    const currentPage = location.pathname

    useEffect(()=>{
        if(location.pathname === currentPage){
            window.scrollTo(0,0)
        }
    }, [location])

    /*
      * Cursor Effect
      * */
    //context
    const value = useContext(AIContext)

    //Cursor Size
    function handleMouseOver (){
        value.setIsHovering(true)
    }

    function handleMouseLeave(){
        value.setIsHovering(false)
    }

    return(
        <div className={`ai-navigation__wrapper ${className}`}>
            <Container>
                <Row className={'g-0'}>
                    <Col lg={6} className={'ai-navigation__left'}>
                        <div className={'ai-navigation__title'}>
                            <h1 className={'ai-text-white'}>Menu</h1>
                        </div>
                        <div className={'ai-navigation__menu'}>
                            <ul>
                                <li style={{"--animation-delay": 1} as React.CSSProperties} onClick={()=>toggleNavigation()} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}><Link to={'/'}>home</Link></li>
                                <li style={{"--animation-delay": 2} as React.CSSProperties} onClick={()=>toggleNavigation()} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}><Link to={'/services'}>our services</Link></li>
                                {/*<li style={{"--animation-delay": 3} as React.CSSProperties} onClick={()=>toggleNavigation()} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}><Link to={'/blog'}>articles</Link></li>*/}
                                <li style={{"--animation-delay": 4} as React.CSSProperties} onClick={()=>toggleNavigation()} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}><Link to={'/careers'}>careers</Link></li>
                                <li style={{"--animation-delay": 5} as React.CSSProperties} onClick={()=>toggleContact()} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}><Link to={'#'}>contact us</Link></li>
                            </ul>
                        </div>
                    </Col>
                    <Col lg={6} className={'ai-navigation__right'}>
                        <Row>
                            <Col lg={{span: 7, offset: 1 }} >
                                <div className={'ai-navigation__cta'}>
                                    <p className={'ai-text-white-0-3'}>Embrace change and discover infinite possibilities
                                        Thanks for your interest in KingaGlobal. We’d love to chat more.</p>
                                    <Link to={'#'} className={'ai-link yellow'} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} onClick={()=> value.handleToggleContact()}>General enquires</Link>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AINavigation;