import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/main.scss'
import './functions/js_composer.min.css'


//Pages Imports
import Router from "./Router";
import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <Router/>
        <div className={'ai-background-effect'}></div>
    </BrowserRouter>
);